@font-face {
    font-family: 'Geometria';
    src: url('../../assets/fonts/Geometria-ExtraLight.eot');
    src: url('../../assets/fonts/Geometria-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Geometria-ExtraLight.woff2') format('woff2'),
    url('../../assets/fonts/Geometria-ExtraLight.woff') format('woff'),
    url('../../assets/fonts/Geometria-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria';
    src: url('../../assets/fonts/Geometria-Medium.eot');
    src: url('../../assets/fonts/Geometria-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Geometria-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Geometria-Medium.woff') format('woff'),
    url('../../assets/fonts/Geometria-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Geometria';
    src: url('../../assets/fonts/Geometria.eot');
    src: url('../../assets/fonts/Geometria.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Geometria.woff2') format('woff2'),
    url('../../assets/fonts/Geometria.woff') format('woff'),
    url('../../assets/fonts/Geometria.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'icon';
    src:  url('../../assets/fonts/icon.eot');
    src:  url('../../assets/fonts/icon.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icon.ttf') format('truetype'),
    url('../../assets/fonts/icon.woff') format('woff'),
    url('../../assets/fonts/icon.svg?#icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
    content: "\e907";
}
.icon-download:before {
    content: "\e913";
}
.icon-mail:before {
    content: "\e911";
}
.icon-phone:before {
    content: "\e912";
}
.icon-plus:before {
    content: "\e908";
}
.icon-bell:before {
    content: "\e900";
}
.icon-calendar:before {
    content: "\e901";
}
.icon-clock:before {
    content: "\e902";
}
.icon-downloads:before {
    content: "\e903";
}
.icon-draft:before {
    content: "\e904";
}
.icon-event:before {
    content: "\e905";
}
.icon-graph-up:before {
    content: "\e906";
}
.icon-password:before {
    content: "\e909";
}
.icon-profile:before {
    content: "\e90a";
}
.icon-project-check:before {
    content: "\e90b";
}
.icon-project:before {
    content: "\e90c";
}
.icon-resume:before {
    content: "\e90d";
}
.icon-search-user:before {
    content: "\e90e";
}
.icon-users:before {
    content: "\e90f";
}
.icon-worker:before {
    content: "\e910";
}
