
.ant-btn{
    outline: none;
    text-align: center;
    position: relative;
    cursor: pointer;
    border: 0;
    border-radius: 0;
    min-height: 40px;
    white-space: inherit;
    height: auto;
    line-height: normal;
    text-decoration: none;
    display: inline-block;
    overflow: hidden;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    transition: background-color .3s ease-out;
    &:disabled {
        background: #ececec;
        color: #2B3D4E;
        cursor: not-allowed;
        &:hover {
            background: #ececec;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.2), 0 2px 4px 0 rgba(50,50,93,.1);
        }
    }
}
.ant-btn:not(.btn-link-small):active:not([disabled]):active{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(50, 50, 93, 0.1);
    transform: translateY(-1px);
}
.btn-float i{
    display: inline-block;
    line-height: 54px;
}
.btn-link-small{
    text-transform: uppercase;
    color: #4CC2E5;
    line-height: 1.5;
    background: transparent;
    box-shadow: none;
    align-self: center;
    padding: 0;
    &:hover{
        color: #3ea7c5;
    }
    &:active{
        transform: translateY(-1px);
    }
}
.btn-link{
    text-transform: uppercase;
    color: #2B3D4E;
    font-size: 15px;
    height: auto;
}
.btn-link i{
    display: block;
    font-size: 30px;
    margin-bottom: 5px;
}
.btn-default,.ant-btn-primary{
    padding: .675rem .75rem;
    font-size: 15px;
    text-transform: uppercase;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2), 0 2px 4px 0 rgba(50,50,93,.1);
    flex: 1;
}
.btn-default{
    background-color: #fff;
    color: #2B3D4E;
    &.ant-btn-icon-only {
        width: auto;
        max-height: 40px;
        color: #4CC2E5;
    }
}
.btn-default:hover{
    background-color: #ececec;
}
.ant-btn-primary{
    background-color: #FF4136;
    color: #fff;
}
.ant-btn-primary:hover{
    background-color: rgb(230, 43, 32);
}
.btn-float{
    margin: 0;
    bottom: 25px;
    background-color: #FF4136;
    position: fixed;
    color: #fff;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    padding: 0;
    line-height: 54px;
    font-size: 30px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}
.controls{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px 10px;
}
.controls .ant-btn{
    margin:0 5px 10px;
    min-width: min-content;
}
