h1{
    text-transform: uppercase;
    color: #2B3D4E;
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 5px;
}
h2{
    font-size: 11px;
    margin: 0 0 20px;
    text-align: center;
    text-transform: uppercase;
    color: #2B3D4E;
}
h3{
    margin: 25px 0 15px;
    padding-left: 20px;
    overflow: hidden;
    font-size: 13px;
    text-transform: uppercase;
    color: #2B3D4E;
    &::before{
        content: '';
        margin-left: -100%;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 2px;
        background: #2b3d4e;
        margin-right: 10px;
    }
    &::after{
        content: '';
        margin-right: -100%;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 2px;
        background: #2b3d4e;
        margin-left: 10px;
    }
}

.head{
    background: #2B3D4E;
    height: 50px;
    position: fixed;
    z-index: 9;
    width: 100%;
}
.logo{
    display: inline-block;
    background: url("../../assets/logo.svg") no-repeat;
    background-size: auto 20px;
    height: 20px;
    width: 110px;
    margin-top: 15px;
}
.notification,.back{
    font-size: 22px;
    color: #fff;
}
.back{
    float: left;
    padding: 10px 10px 0 0;
    margin-right: 10px;
}
.notification{
    float: right;
    position: absolute;
    right: 0;
}
.footer{
    float: right;
    .max-width{
        width: auto;
    }
}
.menu{
    margin: 0;
    padding: 0;
    color: #fff;
    list-style: none;
    i{
        margin-right: 10px;
    }
}
.menu li{
    display: inline-block;
    flex: 1;
    text-align: center;
}
.menu .active{
    background-color: #FF4136;
    color: #fff;
}
.menu a{
    display: block;
    padding: 12px 16px 13px;
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
}
.content{
    padding: 70px 0;

}
.max-width{
    max-width: 780px;
    margin: 0 auto;
    position: relative;
}
