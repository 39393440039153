html, body{
    margin: 0;
    padding: 0;
    font-family: Geometria, "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
}
body{
    background-color: #E4E4F0;
    font-size: 13px;
    line-height: 1.5;
}
*{
    box-sizing: border-box;
}
a{
    text-decoration: none;
}
.list-box{
    display: flex;
    flex-direction: column;
    .item.hire-request {
        cursor: pointer;
    }
}
.item{
    display: flex;
    min-height: 70px;
}
.box{
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2), 0 2px 4px 0 rgba(50,50,93,.1);
    .gender:not(:last-child){
        &:after{
            content: ', ';
        }
    }
}
.type-box{
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 80px;
    padding: 5px 2px;
    min-height: 70px;
    height: 100%;
}
.type-box i{
    display: block;
    font-size: 24px;
}
.type-box .name{
    margin-top: 5px;
    font-size: 10px;
    text-transform: lowercase;
    font-weight: 500;
    text-align: center;
    word-break: break-word;
}
.sub-title,.date,.time,.gender{
    color: #7D7D7D;
    margin: 2px 0;
}
.date{
    margin-right: 10px;
}
.type-blue{
    background-color: #2B3D4E;
}
.type-light-blue{
    background-color: #4CC2E5;
}
.type-orange{
    background-color: #F5A623;
}
.type-green{
    background-color: #71C57A;
}
.title{
    font-weight: 500;
    font-size: 16px;
    color: #000;
}
.title a{
    color: inherit;
}
.title-box,.other-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.title-box{
    flex: 1;
    padding: 5px 5px 5px 10px;
    min-width: 0;
    .address {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
.other-box{
    flex: 0 0 110px;
    padding: 10px 0;
    font-size: 13px;
    .other-box{
        padding: 0;
        flex: 0 0 auto;
    }
    .btn-link-small{
        align-self: auto;
        font-size: 11px;
    }
}
.title-box i,.other-box i{
    margin-right: 5px;
}
.contact .ant-btn{
    text-transform: inherit;
}
.contact .ant-btn i{
    margin-right: 10px;
}
.mt10{
    margin-top: 10px;
}
.p10{
    padding: 10px;
}
.text-center{
    text-align: center;
}
.flex-end{
    justify-content: flex-end;
    margin: 0;
}
