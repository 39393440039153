.login{
    display: flex;
    display: -ms-flexbox;
    -ms-flex-pack: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 100%;
    -ms-flex-pack: center;
    -ms-flex-direction: column;
    -ms-flex-align: center;
    justify-content: center;
    padding: 10px;
    max-width: 380px;
    margin: 0 auto;
}
.login .form-group, .login .controls, .login form{
    width: 100%;
}
.login .controls{
    flex-direction: column;
    margin: 0;
}
.login .controls .ant-btn{
    margin: 0 0 15px;
}
.logo-red{
    display: inline-block;
    background: url("../../assets/logo-red.svg") no-repeat;
    background-size: auto 30px;
    height: 30px;
    width: 160px;
    margin-bottom: 20px;
}
.guest h1{
    text-align: center;
    margin: 0.5rem 0 1.5rem;
}
.registry .ant-btn{
    margin: 0 0 15px;
}
.guest {
    width: 100%;
    .controls {
        flex-direction: row;
        margin: 0;
        .ant-btn {
            margin: 0 5px !important;
        }
    }
}
