@import "./supervisor/icons.scss";

.ant-layout-sider-children {
    overflow: auto;
}
.ant-form-item-label {
    line-height: 1.5;
}
.ant-form-item-label.ant-col-sm-4 {
    line-height: 40px;
}
.ant-form-item {
    margin-bottom: 20px;
}
body .ant-modal {
    top: 40px;
}
.ant-layout-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
}
.supervisor-layout,
.guest-layout {
    @import 'supervisor.scss';
}
.deadline-expired{
    background-color: #fff1f0;
}
.deadline-soon{
    background-color: #fffbe6;
}
.ant-form-item-control{
    .ant-form-item-control-input {
        min-height: 40px;
    }
    .select{
        .select__option{
            &.select__option--is-selected{
                background-color: #f5f5f5;
                color: inherit;
            }
            &.select__option--is-focused{
                background-color: #e6f7ff;
            }
        }
    }
    .ant-form-item-children .select{
        .select__control{
            &:hover,&.select__control--is-focused{
                border-color: #40a9ff;
            }
        }
    }
    .select__placeholder{
        color: #bfbfbf;
    }
}
.ant-time-picker-panel{
    &.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
        max-width: 150px;
    }
    .ant-time-picker-panel-select{
        width: 75px;
        li{
            text-align: center;
            padding: 0;
        }
    }
}
.ant-input-affix-wrapper {
    border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child), .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child), .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.ant-cascader-picker .ant-input {
    border-radius: 4px;
}
.nowrap, .ant-btn-group{
    white-space: nowrap;
}
.ant-btn-dangerous {
    background-color: #ff4d4f;
    color: #fff;
}
body .ant-tabs{
    overflow: visible;
}
.ant-table-body {
    overflow: auto;
}
.ant-table-content table {
    background-color: #fff;
}
.ant-table-placeholder {
    text-align: center;
    padding: 30px;
    color: #bbb;
}
@media (max-width: 767px){
    body .ant-modal{
        margin: 0;
        padding: 0;
        top: 0;
        max-width: 100%;
        .ant-modal-content{
            min-height: 100vh;
            border-radius: 0;
        }
        .supervisor-layout{
            .form-control, .ant-input, .ant-input, .ant-input-number-input, .select__control, .ant-time-picker-input{
                box-shadow: none;
                border: 2px solid #E4E4F0;
            }
            .ant-input,.ant-input:hover{
                border-right-width: 2px!important;
            }
        }
        .ant-modal-close-x{
            color: #000;
        }
    }
}
.field-error {
    position: absolute;
    margin-top: 4px;
}
.ant-table-wrapper {
    overflow-x: auto;
}
.ant-tabs-nav-list .ant-tabs-tab {
    padding-left: 15px;
    padding-right: 15px;
}
ul.ant-picker-time-panel-column {
    width: 58px;
}

.error-text {
    color: #FF4136;
}

.copy-applicant {
    & > * {
        float: left;
        margin-right: 8px;
    }

    clear: both;
    min-height: 22px;
    margin-top: -12px;
    margin-bottom: 8px;
}

.btn-line {
    .ant-btn + .ant-btn {
        margin-left: 8px;
    }
}

.ant-upload-list-item-info {
    & > span {
        display: inline-block;
        width: auto;
        line-height: 30px;
    }
}

.opd-modal {
    width: 100%;
    height: calc(100vh - 140px);
    border: 0;
    outline: 0;
}
