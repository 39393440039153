@media (max-width: 800px){
    body{
        font-size: 13px;
    }
    .content {
        padding: 60px 10px 70px;
    }
    .flex-end{
        margin-right: 10px;
    }
    .contact{
        flex-direction: column;
    }
    .contact .ant-btn{
        margin-bottom: 10px;
        text-align: left;
    }
    .back{
        position: absolute;
        left: 10px;
        z-index: 2;
    }
    .notification{
        right: 10px;
    }
    .footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        box-shadow: 0 0 6px rgba(0,0,0,.15);
    }
    &.form-layout .footer {
        display: none;
    }
    .head{
        text-align: center;
    }
    .menu{
        display: flex;
        color: #FF4136;
        a{
            padding: 7px 0 2px;
            font-size: 13px;
        }
        i{
            display: block;
            font-size: 22px;
            margin: 0 0 5px;
        }
    }
    .other-box{
        flex: 0 0 90px;
        font-size: 11px;
        .btn-link-small{
            text-transform: lowercase;
        }
    }
    .title{
        font-size: 14px;
        line-height: normal;
    }
    .ant-btn{
        font-size: 14px;
        padding: 0.675rem 0.55rem;
    }
    .btn-float{
        font-size: 26px;
        padding: 0;
        bottom: 80px;
    }
}
